import React from 'react'

const UCLA = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 38">
    <path fillRule="evenodd" clipRule="evenodd" d="M81.578 0H0v37.73h81.578V0Zm-69.75 22.417a17.625 17.625 0 0 1 0-1.99l.95-10.764H16.3l-.993 10.854c-.211 2.354.714 4.024 3.062 4.024s3.666-1.707 3.872-3.788l1.005-11.097h3.515l-.968 10.958c-.4 4.235-3.195 7.375-7.72 7.375-3.528.006-5.803-2.275-6.245-5.572Zm26.338-13.1c-6.026 0-9.511 4.29-9.97 9.451-.43 4.84 2.298 9.27 8.264 9.294a10.146 10.146 0 0 0 4.907-1.35l.393-4.386a6.74 6.74 0 0 1-5.022 2.215c-3.636 0-5.288-2.548-4.991-5.84.296-3.29 2.353-5.898 6.099-5.898a5.204 5.204 0 0 1 4.544 2.093l.405-4.235a8.252 8.252 0 0 0-4.629-1.343Zm7.442.345h3.51L47.84 24.28h5.034c.357 0 1.555 0 1.881-.042 0 .181-.32 3.382-.32 3.382H44.01l1.597-17.958Zm18.968-.822h.242l6.577 18.756h-3.727l-.847-2.85h-6.783l-1.349 2.85h-3.733l9.62-18.756Zm-.4 6.77c-.06.26-.148.514-.26.756-.054.115-2.425 5.367-2.425 5.367h4.44s-1.367-4.544-1.566-5.36a4.87 4.87 0 0 1-.128-.763h-.06Z" fill="currentColor"/>
    <path d="M103.19 29.883v-.932c2.299 0 3.025-.544 3.025-2.238v-7.92h-9.826v7.92c0 1.712.733 2.226 3.262 2.238v.932h-9.409v-.932c2.293 0 3.025-.544 3.025-2.238V10.806c0-1.694-.701-2.22-3.025-2.239v-.931h9.318v.931c-2.475 0-3.195.527-3.195 2.239v6.934h9.85v-6.934c0-1.694-.72-2.22-3.061-2.239v-.931h9.112v.931c-2.245 0-2.929.545-2.929 2.239v15.907c0 1.694.714 2.226 3.062 2.238v.932h-9.209ZM119.665 30.276c-3.902 0-7.085-3.575-7.085-7.962s3.183-7.962 7.085-7.962c4.12 0 6.789 3.025 6.819 7.865h-10.552c0 4.393 1.815 7 4.81 7 2.354 0 3.86-1.567 4.907-2.964l.641.55c-1.252 1.81-2.934 3.473-6.625 3.473Zm-.066-14.993c-2.421 0-3.667 1.96-3.667 5.82v.092h7.406v-.091c0-3.86-1.259-5.82-3.739-5.82ZM132.601 30.276c-2.245 0-4.508-1.131-4.508-3.63 0-3.164 5.143-4.738 8.223-5.681.514-.158.98-.297 1.361-.43h.061V17.39c0-1.555-1.858-2.106-3.443-2.106a5.41 5.41 0 0 0-3.11.83l-.188.114.218.043a1.56 1.56 0 0 1 1.331 1.645 1.816 1.816 0 1 1-3.63 0c0-1.936 2.499-3.563 5.445-3.563 4.49 0 6.238 1.21 6.238 4.41v7.866c0 1.61.382 1.912 1.096 1.912.847 0 1.21-1.21 1.21-2.281h.865c-.03 1.857-.902 3.8-3.273 3.8-1.767 0-2.275-1.047-2.554-2.856v-.073h-.078v.055c-.793 2.111-2.535 3.091-5.264 3.091Zm5.028-8.894c-2.856.69-6.19 2.42-6.19 5.1 0 1.628.884 2.596 2.366 2.596 2.208 0 3.933-2.1 3.933-4.78v-2.946l-.109.03Z" fill="currentColor"/>
    <path d="M143.728 29.883v-.932c1.875 0 2.474-.375 2.474-1.458V10.22c0-1.083-.605-1.44-2.474-1.452V7.83l5.366-.187v19.851c0 1.083.605 1.446 2.481 1.458v.932h-7.847ZM158.182 30.276c-2.771 0-3.951-1.161-3.951-3.89v-10.71h-2.377v-.943c2.952-.266 3.4-1.101 4.235-5.077h1.01v4.962h4.532v1.059h-4.532v11.889c0 1.21.605 1.779 1.882 1.779.55 0 2.341-.351 2.341-3.63v-.57h.866v.466c.018 3.31-1.15 4.665-4.006 4.665ZM174.797 29.883V19.095c0-3.14-1.688-3.63-2.699-3.63-2.39 0-4.06 2.953-4.06 5.597v6.431c0 1.09.605 1.446 2.542 1.458v.932h-7.745v-.932c1.724 0 2.311-.387 2.311-1.458V10.22c0-1.07-.605-1.44-2.311-1.452V7.83l5.203-.187v9.965h.091v-.049a5.036 5.036 0 0 1 4.768-3.206c1.779 0 4.768.762 4.768 5.875v7.26c0 1.071.605 1.446 2.317 1.458v.932l-5.185.006Z" fill="currentColor"/>
  </svg>
)

export default UCLA
