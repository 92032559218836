import React from 'react'

const UCSF = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 180 71">
    <path
      d="M143.701 31.047v-8.832h-32.927V54.76c-.177-3.813-1.542-6.771-4.125-8.852-1.787-1.438-4.469-2.588-8.049-3.447l-8.167-1.965c-3.151-.751-5.212-1.406-6.182-1.965-.892-.494-1.518-1.14-1.883-1.933v.001l-.008-.017a4.552 4.552 0 01-.382-1.856c0-1.455.49-2.693 1.503-3.665v-.003l.067-.063a5.976 5.976 0 01.735-.582c1.532-1.031 3.582-1.546 6.15-1.546 2.308 0 4.24.398 5.793 1.191 2.331 1.204 3.582 3.233 3.755 6.09h9.345c-.171-5.048-2.008-8.897-5.513-11.55-3.506-2.652-7.74-3.979-12.704-3.979-5.945 0-10.452 1.407-13.52 4.22-1.887 1.73-3.19 3.734-3.917 6.007h-.004c-.156.49-.287.998-.39 1.512-1.138 5.477-4.594 9.196-10.372 9.196-9.251 0-12.726-8.031-12.726-15.996 0-8.365 3.475-16.394 12.726-16.394 5.113 0 9.575 3.612 10.23 8.364h9.971C81.86 6.485 73.202.328 62.907.328c-13.825 0-22.213 10.045-22.962 23.28V1.466h-10.3v29.715c0 7.095-2.36 10.374-9.706 10.374-8.33 0-9.642-5.154-9.642-10.374V1.466H0v29.715C0 44.165 7.344 50.39 19.939 50.39c12.529 0 20.005-6.29 20.005-19.208v-3.725c.75 12.889 9.138 22.934 22.962 22.934 5.68 0 10.524-1.87 14.096-5.2.222.18.449.358.689.525 1.852 1.31 5.253 2.524 10.205 3.64l5.038 1.129c2.95.644 5.103 1.385 6.462 2.222 1.357.86 2.037 2.074 2.037 3.64 0 2.686-1.386 4.522-4.158 5.51-1.462.515-3.364.773-5.707.773-3.91 0-6.672-.977-8.284-2.932-.88-1.074-1.472-2.695-1.773-4.864h-9.282c0 4.94 1.755 8.822 5.265 11.646 3.51 2.824 8.329 4.236 14.456 4.236 6 0 10.642-1.434 13.929-4.301 3.023-2.637 4.652-5.898 4.895-9.78v13.367h10.298V50.256h19.611v-8.164h-19.611V31.047h22.629z"
      fill="currentColor"
    />
  </svg>
)

export default UCSF
