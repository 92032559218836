import React, { FC } from 'react'

import Container from 'components/container'
import UCLA from 'components/icons/institutions/ucla'
import UCSF from 'components/icons/institutions/ucsf'
import USCKeck from 'components/icons/institutions/usc-keck'
import Stanford from 'components/icons/institutions/stanford'
import HackensackMeridian from 'components/icons/institutions/hackensack-meridian'
import {
  CTAButton,
  InstitutionLogosSingleRow,
  InstitutionLogosMultiRow,
  Logo,
  Section,
  SectionTitle,
} from './styled'

type Props = {
  title: string
  dark?: boolean
  showCTA?: boolean
  logos?: any
  useSecondaryColor?: boolean
  large?: boolean
}

const Institutions: FC<Props> = ({
  title,
  dark,
  showCTA,
  logos,
  large,
  useSecondaryColor,
}) => {
  const defaultLogos = [
    {
      href: 'https://www.uclahealth.org/urology/',
      ariaLabel: "Visit the UCLA Health urology department website",
      element: <UCLA />,
    },
    {
      href: 'https://urology.ucsf.edu/',
      ariaLabel: "Visit the UCSF urology department website",
      element: <UCSF />,
    },
    {
      href: 'https://urology.keckmedicine.org/',
      ariaLabel: "Visit the Keck Medicine urology department website",
      element: <USCKeck />,
    },
    {
      href: 'https://www.stanfordchildrens.org/en/service/fertility-and-reproductive-health',
      ariaLabel: "Visit the Stanford Children's fertility and reproductive health website",
      element: <Stanford />,
    },
    {
      href: 'https://www.hackensackumc.org/services/urology/our-services/mens-urology/',
      ariaLabel: "Visit the Hackensack University Medical Center urology department website",
      element: <HackensackMeridian />,
    },
  ]

  const displayLogos = logos ? logos : defaultLogos

  return (
    <Section dark={dark} large={large}>
      <Container>
        <SectionTitle large={large}>{title}</SectionTitle>
        {displayLogos.length <= 5 ? (
          <InstitutionLogosSingleRow large={large}>
            {displayLogos.map((logo: any) => (
              <Logo
                key={logo.href}
                secondaryColor={useSecondaryColor}
                large={large}
              >
                <a href={logo.href} aria-label={logo.ariaLabel} target="_blank" rel="noreferrer noopenner">
                  {logo.element}
                </a>
              </Logo>
            ))}
          </InstitutionLogosSingleRow>
        ) : (
          <InstitutionLogosMultiRow large={large}>
            {displayLogos.map((logo: any) => (
              <Logo
                key={logo.href}
                secondaryColor={useSecondaryColor}
                large={large}
              >
                <a href={logo.href} target="_blank" rel="noreferrer noopenner">
                  {logo.element}
                </a>
              </Logo>
            ))}
          </InstitutionLogosMultiRow>
        )}
        {showCTA ? (
          <a
            href="https://meetfellow.typeform.com/to/C7IB6L"
            target="_blank"
            rel="noreferrer noopener"
          >
            <CTAButton>Schedule a call to get a free kit</CTAButton>
          </a>
        ) : null}
      </Container>
    </Section>
  )
}

export default Institutions
