import styled, { css } from 'styled-components'
import { heading } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'
import Button from 'components/button'

export const Section = styled.section<{
  hasBackground?: boolean
  dark?: boolean
  large?: boolean
}>`
  background: ${({ dark }) => (dark ? theme.color.background.gray : '#fff')};
  position: relative;
  ${props =>
    props.large &&
    css`
      padding: 8rem 0;
    `}

  ${mq.medium} {
    padding: 12rem 4rem;
    ${props =>
      !props.large &&
      css`
        padding: 8rem 0 0;
      `}
  }
`

export const SectionTitle = styled.h2<{
  indent?: boolean
  large?: boolean
}>`
  font: 600 1.2rem "Suisse Intl", sans-serif;
  max-width: 90rem;
  margin: 0 auto 4rem;
  text-align: center;
  ${props =>
    props.indent &&
    css`
      text-indent: -0.5em;
    `}
  ${props =>
    props.large &&
    css`
      ${heading.xl};
      text-align: left;
      margin-bottom: 8rem;
    `}

  ${mq.medium} {
    text-align: center;
    ${props =>
      !props.large &&
      css`
        font-size: 1.6rem;
      `}
    ${props =>
      props.large &&
      css`
        margin-bottom: 8rem;
      `}
  }
`

export const Logo = styled.div<{
  secondaryColor?: boolean
  large?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
  ${props =>
    props.large &&
    css`
      flex-basis: auto;
    `}

  a {
    svg {
      width: 100%;
      max-width: 8rem;
      ${props =>
        props.large &&
        css`
          max-width: 15rem;
        `}

      ${mq.medium} {
        max-width: 12rem;
        ${props =>
          props.large &&
          css`
            max-width: 20rem;
          `}
      }
    }

    &:hover {
      svg {
        path {
          fill: ${theme.color.accent.primary};
        }
      }
    }
  }

  ${props =>
    props.secondaryColor &&
    css`
      svg {
        color: ${theme.color.accent.secondary};
      }
    `}
`

export const InstitutionLogosSingleRow = styled.div<{ large?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 3rem;
  ${props =>
    props.large &&
    css`
      gap: 3rem;
      row-gap: 8rem;
    `}

  ${mq.medium} {
    flex-wrap: nowrap;
    margin: 0 -5rem;
  }
`

export const InstitutionLogosMultiRow = styled.div<{ large?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  ${mq.medium} {
    ${Logo} {
      width: 25%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

export const CTAButton = styled(Button as any)`
  margin: 5rem auto 0;
  display: block;
`
